@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
    touch-action: none;
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "SF Pro Display", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    color: black;
}

body {
    /*background-image: url("../images/bg-logo.png");*/
    background-repeat: repeat;
    background-color: black;
}

#screen_layout {
    margin: auto auto;
    inset: 0;
    width: 100%;
    height: 100%;
    position: relative;
    background-color: white;
}

#unity_iframe {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

#root {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

#root > * {
    pointer-events: all;
}

.game-button {
    background: #FFFEFB;
    box-shadow: 0 8px 0 #E7E5DF;
    border-radius: 20px;
    padding: 10px;
}

.game-button-green {
    background: #73D00E;
    box-shadow: 0 8px 0 #6BC00E;
    border-radius: 20px;
    color: white;
}

.game-button-green:disabled {
    background: #BBBBBB;
    box-shadow: 0 8px 0 #555555;
    color: black;
}

@layer components {
    .btn-primary {
        @apply py-2 px-4 m-1 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75;
    }

    .title {
        @apply ml-16 mt-32 text-2xl font-semibold;
    }
}

.disabled {
    pointer-events: none;
}
